.brd {
    padding: 2%;
}

.brd:first-child {
    width: 30%!important;
    border-right: 1px solid grey;
    height: 4.2vh;
}

.sc-wrapper {
    border: 1px solid grey;
    border-radius: 0.2rem;
}

.wrp {
    border-top: 1px solid grey;
}

.num input {
    font-size: 1em !important;
}

.num {
    padding: 2%;
}

.nameOnCard {
    width: 100%;
    border: 1px solid grey;
    border-radius: 0.2rem;
    height: 6vh;
    padding-left: 3%;
}

.country-select {
    border: none;
    width: 100% !important;
}


.country-select:focus {
    outline: none;
}

.input-zip-wrapper {
    border-top: 1px solid grey;
}

.input-zip {
    border: none;
    width: 100%;
    padding-left: 2%;
}

.country-wrp {
    margin: auto;
    width: 90%;
    border: 1px solid grey;
    border-radius: 0.2rem;
}

.sub-btn {
    color: white;
    font-weight: bold;
    font-size: 1.2em;
    border: none;
    height: 9vh;
    background: url(../../../assets/button-background.svg);
    background-size: cover;
    width: 80%;

}
