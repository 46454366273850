.header-img {
    width: 100%;
    height: 100%;
}

.sc-plan-price p:first-child {
    color: #5E5E5E;
    font-size: 7em;
    padding-top: 5%;
}

.sc-plan-price p:nth-child(2) {
    font-size: 2em;
    margin-top: 6vh;
    color: #5E5E5E;
}

.sc-sub-btn {
    color: white;
    font-weight: bold;
    font-size: 1.5em;
    border: none;
    height: 8vh;
    background: url(../../../assets/button-background.svg);
    background-size: cover;
    width: 80%;
}

.sc-sub-btn-card {
    color: white;
    font-weight: bold;
    font-size: 1.5em;
    border: none;
    background: url(../../../assets/button-background.svg);
    background-size: cover;
    width: 80%;
    height: 9vh;
}

.gpay {
    font-size: 15em;
    vertical-align: center;
}

.gpay-img {
    width: 50vw;
}

.gpay-wrapper {
    width: 65%;
    border: 10px solid black;
    border-radius: 5rem;
}

.img-wrapper img {
    max-width: 25vw;
}
